<template>
    <div>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- Breadcrumb -->
                <div class="position-fixed w-100" style="top:0;z-index:99;transform:translateX(-1px);">
                    <div class="d-flex mb-4 pt-3 pb-3 border-bottom align-items-center bg-white">
                        <router-link :to="{ name: 'admin.dashboard' }">Beranda</router-link>
                        <span class="text-muted mx-2">|</span>
                        <div class="text-muted">Profil Sekolah</div>
                    </div>
                </div>
                <!-- -->
                <!-- Header -->
                <div class="d-block mb-4 pt-5 mt-3 pb-3">
                    <div class="d-flex align-items-center">
                        <div class="mr-3">
                            <img src="https://scolacdn.com/frontend/argonne-img/class-icon-2.png" width="38">
                        </div>
                        <div>
                            <h1 class="header-title">
                                Profil Sekolah
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <!-- FORM -->
                        <form @submit.prevent="submitForm" enctype="multipart/form-data" class="mb-4">
                            <div class="d-block">
                                <div class="form-group">
                                    <label class="font-weight-bold">Nama Sekolah <span class="text-primary">*</span></label>
                                    <input type="text" class="form-control" v-model="form.school_title">
                                </div>
                                <div class="form-group">
                                    <label class="font-weight-bold">Sub Nama Sekolah <span class="text-primary">*</span></label>
                                    <input type="text" class="form-control" v-model="form.school_sub_title">
                                </div>
                                <div class="card bg-light">
                                    <div class="card-body">
                                        <div class="form-group m-0">
                                            <label class="font-weight-bold">Telepon <span class="text-primary">*</span></label>
                                            <div v-for="(row, index) in form.phone" :key="index">
                                                <div class="d-flex align-items-center mb-3">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><span class="fe fe-phone"></span></span>
                                                        </div>
                                                        <input type="text" class="form-control m-0 mr-2" v-model="form.phone[index]" placeholder="Masukan nomor telepon">
                                                    </div>
                                                    <div class="flex-shrink-0">
                                                        <button type="button" class="btn btn-white text-danger m-0" v-on:click="deleteFormPhone(index)"><i class="fe fe-trash" data-toggle="tooltip" data-placement="top" data-title="Hapus"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" class="btn text-primary btn-white" v-on:click="addFormPhone()"><i class="fe fe-plus mr-2"></i>Tambah Telepon</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="font-weight-bold">Email <span class="text-primary">*</span></label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><span class="fe fe-mail"></span></span>
                                        </div>
                                        <input type="text" class="form-control" v-model="form.email">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="font-weight-bold">Website<span class="text-primary">*</span></label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><span class="fe fe-globe"></span></span>
                                        </div>
                                        <input type="text" class="form-control" v-model="form.website">
                                    </div>
                                </div>
                                <div class="card bg-light">
                                    <div class="card-body">
                                        <div class="form-group m-0">
                                            <label class="font-weight-bold">Address <span class="text-primary">*</span></label>
                                            <div v-for="(row, index) in form.address" :key="index">
                                                <div class="d-flex mb-3">
                                                    <textarea class="form-control mb-0 mr-2" v-model="form.address[index]" rows="5" placeholder="Masukan alamat"></textarea>
                                                    <div class="flex-shrink-0">
                                                        <button type="button" class="btn btn-white text-danger" v-on:click="deleteFormAddress(index)"><i class="fe fe-trash" data-toggle="tooltip" data-placement="top" data-title="Hapus"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" class="btn text-primary btn-white" v-on:click="addFormAddress()"><i class="fe fe-plus mr-2"></i>Tambah Alamat</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-7">
                                        <label class="font-weight-bold">Logo <span class="text-primary">*</span></label>
                                        <div class="fallback">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="customFileUploadMultiple" multiple>
                                                <label class="custom-file-label" for="customFileUploadMultiple">Choose file</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Action -->
                            <div class="d-flex pt-4 mt-2 border-top">
                                <button type="submit" class="btn btn-primary"><i class="fe fe-save mr-2"></i>Update Profil Sekolah</button>
                            </div>
                            <div class="spacer"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                baseUrl: this.$apiconfig,
                form: {
                    school_title: '',
                    school_sub_title: '',
                    phone: '',
                    email: '',
                    website: '',
                    address: ''
                },
                totalAddress: 1,
                totalPhone: 1
            }
        },
        created() {
            this.getData();
            // console.log('The id is: ' + this.$route.params.id);
        },
        methods: {
            convertTitle() {
                var slug = "";
                var titleLower = this.form.title.toLowerCase();
                slug = titleLower.toLowerCase()
                    .replace(/\s+/g, "-")
                    .replace(/&/g, `-and-`)
                    .replace(/--/g, `-`);
                this.form.slug = slug;
            },
            getData() {
                this.fetching = true;
                this.$http.get(this.$apiconfig + `admin/school_profile`)
                    .then(response => {
                        if (response.data.status) {
                            this.form = response.data.data[0];
                            const currentTotalAddress = this.form.address.length;
                            this.totalAddress = currentTotalAddress;
                        }
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            submitForm() {
                if (this.form.title == '' || this.form.description == '') {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'inputan tidak boleh kosong',
                    })
                } else {
                    this.$http.post(this.baseUrl + 'admin/school_profile/save', this.form)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: 'Data school profile berhasil diubah',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.getData()
                        })
                        .catch(error => {
                            console.log(error.response.data)
                        }).finally(() => {
                            //Perform action in always
                        });
                }
            },
            addFormAddress() {
                this.totalAddress++;
                this.form.address.push('');
            },
            deleteFormAddress(index) {
                this.form.address.splice(index, 1);
            },
            addFormPhone() {
                this.totalPhone++;
                this.form.phone.push('');
            },
            deleteFormPhone(index) {
                this.form.phone.splice(index, 1);
            }
        },
    };
</script>